import Header from "../components/Header";
import Layout from "../layouts/Layout";

export default function Research() {

  const researchText = [
    {
      title: "Sparse is Eco-Friendly, but Dense is Effective: Inferences from Package Design",
      with: "with Felipe M. Affonso and Aner Sela",
      extra: "Under Review at Journal of the Academy of Marketing Science",
    },
    {
      title: "Hypocritical Consumption",
      with: "with Chris Janiszewski and Felipe M. Affonso",
      extra: "Under Review at Journal of Consumer Research",
    },
    {
      title: "The Materialization of Experiences: How Experience Depth Increases Consumers’ Preference for Unique Objects",
      with: "with Wilson Bastos and Travis Oh",
      extra: "Under Review at Journal of Association for Consumer Research",
    },
    {
      title: "The Fun and Function of Rotational Variety",
      with: "with Gia Nardini and Richard Lutz",
      extra: "Data Collection in Progress",
    },
    {
      title: "Preference for Freeform Design",
      with: "with Travis Oh",
      extra: "Data Collection in Progress",
    },
    {
      title: "Ownership Decreases Sustainable Behavior",
      with: "with Xiang Wang and Chris Janiszewski",
      extra: "Data Collection in Progress",
    },
    {
      title: "Gendered AI Interaction",
      with: "with Geoff Tomaino",
      extra: "Data Collection in Progress",
    },
    {
      title: "Logo Re-Design and Brand Perception: Insights from Convolutional Neural Network",
      with: "with Guangzhi Chen",
      extra: "Data Collection in Progress; Multi-Method",
    },
    {
      title: "Learning AI-Generated Image Design from Multimodal Information",
      with: "with Zhiyu Zhang and Guangzhi Chen",
      extra: "Data Collection in Progress; Multi-Method",
    },
  ]

  const presentationText = [
    {
      paper: 'The Fun and Function of Rotational Variety*',
      with: ' (Association for Consumer Research, 09/2024)',
    },
    {
      paper: 'The Materialization of Experiences: How Experience Depth Increases Consumers’ Preference for Unique Objects*',
      with: ' (Association for Consumer Research, 09/2024)',
    },
    {
      paper: 'Simple is Eco-Friendly, but Complex is Effective: Inferences from Package Design*',
      with: ' (Association for Consumer Research, 09/2024)',
    },
    {
      paper: 'When and How Deep Experiences Increase Preferences for Unique Objects*',
      with: ' (Winter AMA, 02/2024)',
    },
    {
      paper: 'Simple is Eco-Friendly, but Complex is Effective: Inferences from Package Design*',
      with: ' (Southeast Marketing Symposium, 04/2023)',
    },
    {
      paper: 'Simple is Eco-Friendly, but Complex is Effective: Inferences from Package Design*',
      with: ' (Society for Consumer Psychology, 03/2023)',
    },
    {
      paper: 'Signal Can Obscure Art Send?: The Role of Processing Fluency in Signaling Social Class Through Art*',
      with: ' (Association for Consumer Research, 10/2019)',
    },
    {
      paper: '"Art Won\'t Let You Down” - The Effect of Fluency in Art Consumption on Status Inference*',
      with: ' (Korean Marketing Association, 03/2019)',
    },
  ]

  return (
    <Layout onlyHeader={false} helmet={{ title: 'Research' }}>

      <Header headerText="Research in Progress" />
      <div className="w-full bg-white flex flex-col items-center font-main">
        <div className="w-4/5 md:w-2/3 lg:w-1/2 my-10 divide-y divide-black">
        {
          researchText.map((item) => 
            <div className="py-10">
                <div className="text-3xl font-bold">{item.title}</div>
                {/* <div className="font-bold">{item.title}</div> */}
                <div className="mt-1">{item.with}</div>
                <div className="mt-3 text-sm italic">{item.extra}</div>
            </div>
          )
        }
        </div>
      </div>

      <Header headerText="Conference Presentation" />
      <div className="pt-16">
        <img src="/images/ryusu_conference.webp" alt="Sooyon Ryu, Conference" width="600" />
      </div>

      <div className="w-full bg-white flex flex-col items-center font-main">
        <div className="w-4/5 md:w-2/3 lg:w-1/2 mt-14 italic font-semibold">(*Denotes presentation by Soo Yon Ryu)</div>
        <div className="w-4/5 md:w-2/3 lg:w-1/2 mt-1 mb-10 divide-y divide-black">
        {
          presentationText.map((item, i) => 
            <div className={`first:pt-5 pt-10 pb-10 flex flex-col md:flex-row`}>
              <div className="pr-3">
                <div className="">
                  <p className="text-xl font-bold inline">{item.paper}</p>
                  <p className="font-semibold inline">{item.with}</p>
                </div>
                {/* <div className="font-light text-sm mt-2">{item.title}</div>
                <ul className="mt-4">
                  {
                    item.details.map(each => <li className="list-disc ml-6">{each}</li>)
                  }
                </ul> */}
              </div>
            </div>
          )
        }

        {
          // OUTDATED ON FEB 2023
          // presentationText.map((item) => 
          //   <div className="py-10 flex flex-col md:flex-row">
          //     <div className="md:w-4/5 pr-3">
          //       <div className="font-bold">{item.title}</div>
          //       <ul>
          //         <li className="list-disc ml-6">{item.desc}</li>
          //         <li className="list-disc ml-6"><bold className="font-semibold">Ryu, Soo Yon</bold>{item.paper}</li>
          //       </ul>
          //     </div>
          //     <div className="mt-4 md:mt-0 md:w-1/5 flex md:block">
          //       {
          //         item?.links.map((link, index) => 
          //           <a href={link.url} target="_blank" rel="noreferrer" className="ml-4 md:ml-0">
          //             { index !== 0 && <div className="md:mt-4"></div> }
          //             <div className={`bg-[#5D5754] w-[135px] h-[40px] text-sm text-white flex justify-center items-center hover:cursor-pointer hover:opacity-50 transition`}>
          //               {link.title}
          //             </div>
          //           </a>
          //         )
          //       }
          //     </div>
          //   </div>
          // )
        }
        </div>
      </div>

    </Layout>
  );
}
