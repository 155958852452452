import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function Navbar() {

  const [menuClicked, setMenuClicked] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const navList = [
    {
      name: 'Home',
      url: '/'
    },
    // {
    //   name: 'About Me',
    //   url: '/about'
    // },
    {
      name: 'Academics',
      url: '/academics'
    },
    {
      name: 'Teaching',
      url: '/teaching'
    },
    {
      name: 'Research',
      url: '/research'
    },
    {
      name: 'Field Experience',
      url: '/field-experience'
    },
    {
      name: 'CV',
      url: '/cv'
    },
    {
      name: 'Contact',
      url: '/contact'
    },
  ]


  return (
    <>
      <div className="bg-white font-main w-full flex items-center justify-between h-[150px] px-6 md:px-4 select-none">
        <div className="flex items-center">
          <div>
            <h1 className="text-3xl md:text-xl font-semibold">Soo Yon Ryu</h1>
            <p className="text-sm">Marketing Ph.D. Candidate</p>
            <p className="text-sm mt-3 hidden sm:block">Warrington College of Business,</p>
            <p className="text-sm -mt-1 hidden sm:block">University of Florida, Gainesville, FL, USA.</p>
          </div>
          {/* <div className="hidden xl:block pl-14">
            <img src="/images/stand_with_ukraine.svg" alt="Stand with Ukraine" className="h-[150px]" />
          </div> */}
        </div>
        <div className="hidden lg:flex items-start space-x-3">
          {
            navList.map((nav, i) =>
              <div key={i} onClick={() => navigate(nav.url)} className={`border-t-[1px] pb-4 border-black w-20 cursor-pointer hover:border-t-4 hover:opacity-50 ` + (nav.url === location.pathname && `border-t-4`)}>
                <div className="mt-4 text-center text-sm">
                  {nav.name}
                </div>
              </div>
            )
          }
        </div>
        {/* mobile menubar button */}
        <div
          onClick={() => setMenuClicked(prev => !prev)}
          className="lg:hidden hover:cursor-pointer hover:opacity-50 border-2 border-spacing-8 p-1 rounded-md"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-8 h-8">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        </div>
      </div>
      
      {/* mobile menubar */}
      {
        // <div className={menuClicked ? `visible` : `hidden`}>
        // <div className={`transition-transform ease-in duration-500 z-10 bg-[#2f2c2b] ` + (menuClicked ? `translate-x-100` : `translate-x-0`)}>
        //   {/* the menu part */}
        //   <div className={`h-screen w-2/3 bg-[#2f2c2b] fixed top-0 left-0 `}>
        //     <div className="w-full h-full flex flex-col justify-center items-center -mt-10 space-y-3 text-white divide-y-[1px]">
        //       {
        //         navList.map((nav, i) =>
        //           <div key={i} onClick={() => navigate(nav.url)} className={`py-4 w-20 cursor-pointer select-none`}>
        //             <div className={`pt-4 text-center text-md hover:opacity-50 hover:transition-all ` + (nav.url === location.pathname && `font-bold text-pink-500`)}>
        //               {nav.name}
        //             </div>
        //           </div>
        //         )
        //       }
        //     </div>
        //   </div>
        //   {/* the background part */}
        //   <div onClick={() => setMenuClicked(prev => !prev)} className="h-screen w-1/3 fixed top-0 right-0">

        //   </div>
        // </div>
        <div className={`h-screen w-screen fixed top-0 left-0 transition-transform ease-in-out duration-300 z-10 ` + (menuClicked ? `translate-x-0` : `-translate-x-full`)}>
          <div className="h-full w-full flex flex-row">
            {/* the menu part */}
            <div className={`h-screen w-2/3 bg-[#2f2c2b]`}>
              <div className="w-full h-full flex flex-col justify-center items-center my-auto space-y-3 text-white divide-y-[1px]">
                {
                  navList.map((nav, i) =>
                    <div key={i} onClick={() => navigate(nav.url)} className={`py-4 w-20 cursor-pointer select-none`}>
                      <div className={`${i !== 0 && "pt-4"} text-center text-md hover:opacity-50 hover:transition-all ` + (nav.url === location.pathname && `font-bold text-[#68A679]`)}>
                        {nav.name}
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
            {/* the background part */}
            <div onClick={() => setMenuClicked(prev => !prev)} className="h-screen w-1/3">
          </div>
          </div>
        </div>
        
      }
    
    </>
  );
}
