import Header from "../components/Header";
import Layout from "../layouts/Layout";

export default function FieldExperience() {

  return (
    <Layout onlyHeader={false} helmet={{ title: 'Field Experience' }}>

      <Header headerText="Field Experience" />
      <div className="w-full bg-white flex flex-col items-center font-main">
        <div className="w-4/5 md:w-2/3 lg:w-1/2 my-10">
          
          {/* <div className="py-10 flex flex-col items-center sm:flex-row">
            <div className="w-2/5 sm:w-1/4">
              <img src="/images/logo-uf.webp" alt="University of Florida" />
            </div>
            <div className="w-full sm:w-3/4 mt-8 sm:mt-0 sm:ml-10">
              <div className="text-3xl font-bold">Research Assistant</div>
              <div className="text-xl mt-4 font-bold">Assistant to Professor Chris Janiszewski, Aner Sela, and Yang Yang at the University of Florida</div>
              <ul className="ml-8 text-lg leading-5">
                <li className="list-disc mt-6">Date: August 2020 - present</li>
                <li className="list-disc mt-1">Duties:</li>
                <li className="list-disc mt-1 ml-8">Collaborative research projects</li>
              </ul>
            </div>
          </div> */}

          <div className="py-10 flex flex-col items-center sm:flex-row">
            <div className="w-2/5 sm:w-1/4">
              <img src="/images/logo-hyundai.svg" alt="Hyundai" />
            </div>
            <div className="w-full sm:w-3/4 mt-8 sm:mt-0 sm:ml-10">
              <div className="text-3xl font-bold">Reporter</div>
              <div className="text-xl mt-4 font-bold">Hyundai Motor Group</div>
              <ul className="ml-8 text-lg leading-5">
                <li className="list-disc mt-6">Date: December 2022 - present</li>
                <li className="list-disc mt-1">Writing seasonal consumer trend reports for HMG</li>
              </ul>
            </div>
          </div>

          <div className="py-10 flex flex-col items-center sm:flex-row">
            <div className="w-2/5 sm:w-1/4">
              <img src="/images/logo-ryusustore.webp" alt="Ryusustore" />
            </div>
            <div className="w-full sm:w-3/4 mt-8 sm:mt-0 sm:ml-10">
              <div className="text-3xl font-bold">Founder</div>
              <div className="text-xl mt-4 font-bold">Ryusutore (Custom Arts Merchandise Store)</div>
              <ul className="ml-8 text-lg leading-5">
                <li className="list-disc mt-6">Date: 2017 September - 2019 May</li>
                <li className="list-disc mt-1">Established a custom manufacturing infrastructure</li>
                <li className="list-disc mt-1">Secured funding from Seoul City & Seoul National Univerisity</li>
                <li className="list-disc mt-1">Collaborated with a prominent publisher</li>
                <li className="list-disc mt-1">Organized popup stores and held multiple classes</li>
              </ul>
            </div>
          </div>

          <div className="py-10 flex flex-col items-center sm:flex-row">
            <div className="w-2/5 sm:w-1/4">
              <img src="/images/logo-igoree.webp" alt="Igoree" />
            </div>
            <div className="w-full sm:w-3/4 mt-8 sm:mt-0 sm:ml-10">
              <div className="text-3xl font-bold">Co-Founder</div>
              <div className="text-xl mt-4 font-bold">Igoree (Modern Hanbok Curation Platform)</div>
              <ul className="ml-8 text-lg leading-5">
                <li className="list-disc mt-6">Date: 2016 March - 2017 December</li>
                <li className="list-disc mt-1">Developing and executing a start-up business</li>
                <li className="list-disc mt-1">Secured government & public business funding (approximately 14,000 USD)</li>
                <li className="list-disc mt-1">Organized multiple offline sales events and classes</li>
                <li className="list-disc mt-1">Reference: Jinwoo Cheon – <a className="underline" href="mailto:jinwoo.cheon@yonsei.ac.kr">jinwoo.cheon@yonsei.ac.kr</a></li>
              </ul>
            </div>
          </div>

          <div className="py-10 flex flex-col items-center sm:flex-row">
            <div className="w-2/5 sm:w-1/4">
              <img src="/images/logo-lg.webp" alt="LG Electronics" />
            </div>
            <div className="w-full sm:w-3/4 mt-8 sm:mt-0 sm:ml-10">
              <div className="text-3xl font-bold">Intern Researcher</div>
              <div className="text-xl mt-4 font-bold">LG Electronics x Design Factory Korea</div>
              <ul className="ml-8 text-lg leading-5">
                <li className="list-disc mt-6">Date: 2015 July - 2015 November</li>
                <li className="list-disc mt-1">Conducted qualitative consumer research to develop new products and services (IoT device and mobile application)</li>
                <li className="list-disc mt-1">Managed project responsibilities, timelines, and sources for planners, designers, and developers</li>
                <li className="list-disc mt-1">Branded new product and service (e.g. naming, logo, brand story)</li>
                <li className="list-disc mt-1">Final work <a className="underline" href="https://drive.google.com/drive/folders/1nmeTNfd21OLxvkrp0_EehfKwn-wyJ7Pv?usp=sharing" target="_blank" rel="noreferrer">patented</a> at the Korean Intellectual Property Office</li>
                <li className="list-disc mt-1">Reference: Soojin Jun – <a className="underline" href="mailto:soojinjun@yonsei.ac.kr">soojinjun@yonsei.ac.kr</a></li>
              </ul>
            </div>
          </div>

          {/* <div className="py-10 flex flex-col items-center sm:flex-row">
            <div className="w-2/5 sm:w-1/4">
              <img src="/images/logo-theater.webp" alt="Yonsei Theater Art Research Club" />
            </div>
            <div className="w-full sm:w-3/4 mt-8 sm:mt-0 sm:ml-10">
              <div className="text-3xl font-bold">PR Manager</div>
              <div className="text-xl mt-4 font-bold">Yonsei Theater Art Research Club</div>
              <ul className="ml-8 text-lg leading-5">
                <li className="list-disc mt-6">2015 January - 2015 July</li>
                <li className="list-disc mt-1">Designed advertisements and established distribution platforms</li>
                <li className="list-disc mt-1">Securing funding for theater projects</li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>

      <Header headerText="Awards" />
      <div className="w-full bg-white flex flex-col items-center font-main">
        <div className="w-4/5 md:w-2/3 lg:w-1/2 my-10">

          <div className="py-10 flex flex-col items-center sm:flex-row">
            <div className="w-2/5 sm:w-1/4">
              <img src="/images/logo-sk.webp" alt="SK Group" />
            </div>
            <div className="w-full sm:w-3/4 mt-8 sm:mt-0 sm:ml-10">
              <div className="text-3xl font-bold">SK Soaring Youth Competition (2016)</div>
              <div className="text-xl mt-4 font-bold">SK Group</div>
              <ul className="ml-8 text-lg leading-5">
                <li className="list-disc mt-6">Winner</li>
                <li className="list-disc mt-1">Competition among university students on start-up business plan</li>
                <li className="list-disc mt-1">Pitched business plan based on market and consumer research</li>
              </ul>
            </div>
          </div>

          <div className="py-10 flex flex-col items-center sm:flex-row">
            <div className="w-2/5 sm:w-1/4">
              <img src="/images/logo-yujin.webp" alt="Yujin Robot" />
            </div>
            <div className="w-full sm:w-3/4 mt-8 sm:mt-0 sm:ml-10">
              <div className="text-3xl font-bold">Yujin Robot Smart Toy Competition (2015)</div>
              <div className="text-xl mt-4 font-bold">Yujin Robot</div>
              <ul className="ml-8 text-lg leading-5">
                <li className="list-disc mt-6">2nd prize winner</li>
                <li className="list-disc mt-1">Competition on smart (IoT) toy design & development</li>
                <li className="list-disc mt-1">Developed IoT smart toy for children based on qualitative consumer research</li>
                <li className="list-disc mt-1">Designed product and mobile phone application design</li>
                <li className="list-disc mt-1">Additional information on the project can be found <a className="underline" href="https://drive.google.com/open?id=0B9lcpT5nSFOlQWxsVFh5ZjN1VUU" target="_blank" rel="noreferrer">here</a> (in Korean)</li>
              </ul>
            </div>
          </div>

          <div className="py-10 flex flex-col items-center sm:flex-row">
            <div className="w-2/5 sm:w-1/4">
              <img src="/images/logo-bag.webp" alt="Lapalette" />
            </div>
            <div className="w-full sm:w-3/4 mt-8 sm:mt-0 sm:ml-10">
              <div className="text-3xl font-bold">Lapalette Indie Brand Competition (2013)</div>
              <div className="text-xl mt-4 font-bold">Beaucre Merchandising</div>
              <ul className="ml-8 text-lg leading-5">
                <li className="list-disc mt-6">Winner</li>
                <li className="list-disc mt-1">Competition on product design</li>
                <li className="list-disc mt-1">Designed a line of fashion products and engaged in the manufacturing process</li>
                <li className="list-disc mt-1">{`Designed products were presented at the <Who's Next> exhibition in Paris`}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  );
}
