import Layout from "../layouts/Layout";


export default function Contact() {
  return (
    <Layout headerText="Contact" helmet={{ title: 'Contact' }}>
      <div className="flex flex-col justify-center items-center w-4/5 font-light my-20 md:my-40">
        <div className="flex flex-col justify-center items-center text-center">
          {/* <div className="w-4/5 md:w-1/2">
            <div className="font-semibold">Dear Ph.D. applicants.</div>
            <div className="mt-5 font-semibold">I do not feel comfortable providing additional information to select applicants on the basis of contact.
            Please do not reach out to me before receiving formal acceptance to the program.  
            If you have any inquiries, please speak with the Ph.D. program coordinator, Professor Aner Sela. 
            Wishing you the best of luck!</div>
          </div> */}
          {/* <div className="mt-5">-</div> */}
          <div className="italic">Stuzin Hall,1454 Union Rd, Gainesville, FL 32611, USA.</div>
          <div className="italic mt-10">(Note: The map embedded below is provided by my website host.</div>
          <div className="italic">Regional names used in the map do not reflect my political standing.)</div>
        </div>
        <div className="mt-10 underline"><a href="mailto:SooYon.Ryu@warrington.ufl.edu">SooYon.Ryu@warrington.ufl.edu</a></div>
      </div>

      <div className="mb-40">
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe className="w-[350px] sm:w-[600px] md:w-[640px] lg:w-[768px] xl:w-[1024px]" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=Stuzin%20Hall,1454%20Union%20Rd,%20Gainesville,%20FL%2032611,%20USA.&t=&z=17&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" title="Google Map" />    
          </div>
        </div>
      </div>
    </Layout>
  );
}
