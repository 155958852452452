import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import Academics from "./pages/Academics";
import Research from "./pages/Research";
import FieldExperience from "./pages/FieldExperience";
import CV from "./pages/CV";
import Contact from "./pages/Contact";
import Teaching from "./pages/Teaching";


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/about" element={<About />} /> */}
        <Route path="/academics" element={<Academics />} />
        <Route path="/teaching" element={<Teaching />} />
        <Route path="/research" element={<Research />} />
        <Route path="/field-experience" element={<FieldExperience />} />
        <Route path="/cv" element={<CV />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  )
}