
export default function Header({ headerText="" }) {
  return (
    headerText &&
    <div className="flex flex-col w-full bg-[#2f2c2b] text-white font-main text-4xl sm:text-5xl md:text-6xl font-bold py-16">
      <div className="flex flex-col w-full text-center">
        { headerText }
      </div>
    </div>
  );
}
