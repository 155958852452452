import Layout from "../layouts/Layout";
import ViewSDKClient from "../sdk/ViewSDKClient";

export default function CV() {

  // DOC: https://github.com/adobe/pdf-embed-api-samples/blob/master/More%20Samples/React%20Samples/src/samples/Embed%20Modes/SizedContainer.js
  const viewSDKClient = new ViewSDKClient();
  viewSDKClient.ready().then(() => {
    /* Invoke file preview */
    viewSDKClient.previewFile("pdf-div", {
        /* Pass the embed mode option here */
        embedMode: "SIZED_CONTAINER"
    });
  });

  const GOOGLE_DRIVE_PDF = "https://drive.google.com/file/d/1gMUC41hnhnhXvD9lRZRLWO4JoOCpifr8/view";

  return (
    <Layout headerText="CV" helmet={{ title: 'CV' }}>
      <div className="flex flex-col mt-20 mb-40 space-y-20 justify-center">
        <div id="pdf-div" className="sized-container-div h-[600px] w-[350px] sm:w-[600px] md:w-[640px] lg:w-[768px] xl:w-[1024px]" />
        <div className="flex justify-center">
          <a href={GOOGLE_DRIVE_PDF} target="_blank" rel="noreferrer">
            <div className="bg-[#2f2c2b] text-white py-3 px-10 hover:opacity-50 hover:transition-colors">
              Download
            </div>
          </a>
        </div>
      </div>
    </Layout>
  );
}
