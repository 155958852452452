
export default function Footer() {
  return (
    <div className="flex flex-col w-full bg-[#2f2c2b] text-white font-main text-xs font-thin py-10">
      <div className="flex flex-col w-full text-center">
        <a href="mailto:SooYon.Ryu@warrington.ufl.edu">SooYon.Ryu@warrington.ufl.edu</a>
        <p>ryusooyon.com</p>
      </div>
      <div className="flex flex-col w-full text-center mt-3">
        <p>Copyright © 2024 Soo Yon Ryu.</p>
        <p>All Rights Reserved.</p>
      </div>
    </div>
  );
}
