import Layout from "../layouts/Layout";

export default function Home() {
  return (
    <Layout onlyHeader={false} helmet={{ title: 'Home' }}>
      <div className="w-full bg-[#2f2c2b] min-h-[100vh] text-white font-main flex justify-center">
        <div className="w-5/6 flex flex-col md:flex-row items-center justify-center py-14 md:py-28">
          {/* Photo */}
          <div className="w-full md:w-[380px] flex justify-center items-center">
            <img src="/images/main_image.webp" alt="Soo Yon Ryu" width="380" />
          </div>
          {/* Text */}
          <div className="space-y-5 md:w-[410px] mt-10 md:mt-0 ml-0 md:ml-10">
            <h1 className="text-5xl md:text-6xl font-bold text-center">Soo Yon Ryu</h1>
            <h3 className="text-lg font-bold text-center">Marketing Ph.D. Candidate</h3>
            <div>
              <h3 className="text-lg font-bold text-center">Warrington College of Business,</h3>
              <h3 className="text-lg font-bold -mt-1 text-center">University of Florida</h3>
            </div>
            <p>Hi there!</p>
            <p className="leading-tight">My name is Soo Yon Ryu and I am a Ph.D. candidate at the University of Florida. My projects circle three main topics of interest – visual & aesthetics, ethical consumption, and inference making. My recent work includes investigating the impact of visual simplicity and complexity on product attribute inferences and developing the Hypocritical Consumption Scale.</p>
            <p className="leading-tight">When I am not working on the aforementioned research, I am diving in the beautiful waters of Florida, practicing yoga, or creating artwork.</p>
            <p className="leading-tight">Feel free to reach out to me for any other information, comments, or a nice chat! :)</p>
          </div>

        </div>
      </div>
    </Layout>
  );
}
