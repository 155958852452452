import Header from "../components/Header";
import Layout from "../layouts/Layout";

export default function Academics() {
  const sAndFshipText = [
    {
      title: "Warrington College of Business Ph.D. Teaching Award (2023)",
    },
    {
      title: "AMA-Sheth Foundation Doctoral Consortium Fellow (2023)",
    },
    {
      title: "Warrington College of Business Behavioral Research Grants (2022)",
    },
    {
      title: "Butterworth Fellowship (2020 – present)",
    },
    {
      title: "Teaching/Research Assistant Scholarship – Seoul National University (2018, 2019)",
    },
    {
      title: "Merit Scholarship – Seoul National University (2019)",
    },
  ];

  return (
    <Layout onlyHeader={false} helmet={{ title: "Academics" }}>
      <Header headerText="Academic Background" />
      <div className="w-full bg-white flex flex-col items-center font-main">
        <div className="w-4/5 md:w-2/3 lg:w-1/2 my-10">
          {/* UF */}
          <div className="py-10 flex flex-col items-center sm:flex-row">
            <div className="w-2/5 sm:w-1/4">
              <img src="/images/logo-uf.webp" alt="University of Florida" />
            </div>
            <div className="w-full sm:w-3/4 mt-8 sm:mt-0 sm:ml-10">
              <div className="text-3xl text-center sm:text-start font-bold">
                University of Florida
              </div>
              <div className="text-xl mt-4 font-bold underline">
                <a
                  href="https://warrington.ufl.edu/phd-in-business-administration-marketing"
                  target="_blank"
                  rel="noreferrer"
                >
                  PhD Candidate in Marketing (Consumer Behavior)
                </a>
              </div>
              <ul className="ml-8 text-lg leading-5">
                <li className="list-disc mt-6">
                  Enrollment: August 2020 - Present
                </li>
                <li className="list-disc mt-1">
                  Advisor:{" "}
                  <a
                    className="underline"
                    href="https://warrington.ufl.edu/directory/person/5693/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Aner Sela
                  </a>{" "}
                  and{" "}
                  <a
                    className="underline"
                    href="https://warrington.ufl.edu/directory/person/5465/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Chris Janiszewski
                  </a>
                </li>
                <li className="list-disc mt-1">
                  Advisory Committee:{" "}
                  <a
                    className="underline"
                    href="https://warrington.ufl.edu/directory/person/5693/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Aner Sela
                  </a>{" "}
                  (Chair),{" "}
                  <a
                    className="underline"
                    href="https://warrington.ufl.edu/directory/person/5465/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Chris Janiszewski
                  </a>{" "}
                  (Co-Chair),{" "}
                  <a
                    className="underline"
                    href="https://warrington.ufl.edu/directory/person/5714/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Yang Yang
                  </a>
                  ,{" "}
                  <a
                    className="underline"
                    href="https://warrington.ufl.edu/directory/profiles/5034/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Alan Cooke
                  </a>
                  ,{" "}
                  <a
                    className="underline"
                    href="https://warrington.ufl.edu/directory/person/5084/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Amir Erez
                  </a>
                </li>
                <li className="list-disc mt-1">
                  Dissertation: Essays on Guiding Consumers Towards Ethical
                  Consumption (Proposed 03/2024)
                </li>
                <li className="list-disc mt-1">
                  Selected Courses: Perspectives on Consumer Behavior, Seminar:
                  Current Topics in Social-Personality Psychology, Experimental
                  Design, Research Methods, Consumer Psychology, Consumer
                  Information Processing and Decision Making
                </li>
              </ul>
            </div>
          </div>
          {/* SNU */}
          <div className="py-10 flex flex-col items-center sm:flex-row">
            <div className="w-2/5 sm:w-1/4">
              <img
                src="/images/logo-seoul.webp"
                alt="Seoul National University"
              />
            </div>
            <div className="w-full sm:w-3/4 mt-8 sm:mt-0 sm:ml-10">
              <div className="text-3xl text-center sm:text-start font-bold">
                Seoul National University
              </div>
              <div className="text-xl mt-4 font-bold underline">
                <a
                  href="https://cba.snu.ac.kr/en"
                  target="_blank"
                  rel="noreferrer"
                >
                  Master of Science in Marketing (Consumer Behavior)
                </a>
              </div>
              <ul className="ml-8 text-lg leading-5">
                <li className="list-disc mt-6">
                  Enrollment: March 2019 - August 2020 (withdrew from the
                  program to pursue PhD program)
                </li>
                <li className="list-disc mt-1">
                  Advisor:{" "}
                  <a
                    className="underline"
                    href="https://gsb.snu.ac.kr/faculty?major=3&idx=1709"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Kyoungmi Lee
                  </a>
                </li>
                <li className="list-disc mt-1">
                  Selected Courses: Studies in Consumer Behavior, Advanced
                  Social Psychology, Marketing Research, Seminar in Consumer
                  Decision Making, Positive Social Psychology
                </li>
              </ul>

              <div className="text-xl mt-6 font-bold underline">
                <a
                  href="http://art.snu.ac.kr/en/category/art-management-program-en"
                  target="_blank"
                  rel="noreferrer"
                >
                  Master of Art Management in Art Management
                </a>
              </div>
              <ul className="ml-8 text-lg leading-5">
                <li className="list-disc mt-6">
                  Enrollment: March 2017 - February 2019
                </li>
                <li className="list-disc mt-1">
                  Advisor:{" "}
                  <a
                    className="underline"
                    href="https://gsb.snu.ac.kr/faculty?major=3&idx=1265"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Sang-Hoon Kim
                  </a>
                </li>
                <li className="list-disc mt-1">
                  Selected Courses: Seminar in Marketing, Special Topics in
                  Consumer Research, Special Topics in Consumer Research
                  Methods, Seminar in Consumer Psychology: Information
                  Processing, Motivation and Persuasion, Management Strategy for
                  the Arts, Emotion and Consumer Judgment Seminar
                </li>
              </ul>
            </div>
          </div>
          {/* Yonsei */}
          <div className="py-10 flex flex-col items-center sm:flex-row">
            <div className="w-2/5 sm:w-1/4">
              <img src="/images/logo-yonsei.webp" alt="Yonsei University" />
            </div>
            <div className="w-full sm:w-3/4 mt-8 sm:mt-0 sm:ml-10">
              <div className="text-3xl text-center sm:text-start font-bold">
                Yonsei University
              </div>
              <div className="text-xl mt-4 font-bold underline">
                <a
                  href="https://ysb.yonsei.ac.kr/default.asp?lang=e"
                  target="_blank"
                  rel="noreferrer"
                >
                  Bachelor of Business Administration in Business Administration
                </a>
              </div>
              <ul className="ml-8 text-lg leading-5">
                <li className="list-disc mt-6">
                  Enrollment: March 2012 - February 2017
                </li>
                <li className="list-disc mt-1">
                  Selected courses: New Product and Service Development, Service
                  Design, Marketing, Entrepreneurial Management, Start-Up 305:
                  Creativity
                </li>
              </ul>

              <div className="text-xl mt-6 font-bold underline">
                <a
                  href="https://uic.yonsei.ac.kr/main/major.asp?mid=m02_03_05#mpart"
                  target="_blank"
                  rel="noreferrer"
                >
                  Bachelor of Science in Culture and Design Management
                </a>
              </div>
              <ul className="ml-8 text-lg leading-5">
                <li className="list-disc mt-6">
                  Enrollment: March 2017 - February 2019
                </li>
                <li className="list-disc mt-1">
                  Advisor:{" "}
                  <a
                    className="underline"
                    href="https://che.yonsei.ac.kr/faculty/name_search.do?mode=view&userId=9eWPK1QD2LJmsmRyJVD0Iw%3D%3D"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Sangwon Lee
                  </a>
                </li>
                <li className="list-disc mt-1">
                  Selected courses: Idea Visualization, Strategic Marketing,
                  Introduction to Culture and Design Business, Techno-Art
                  Capstone Project, Leadership Seminar in Techno-Art
                  Entrepreneurship
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Header headerText="Scholarship & Fellowship" />
      <div className="w-full bg-white flex flex-col items-center font-main">
        <div className="w-4/5 md:w-2/3 lg:w-1/2 my-10">
          <ul>
            {sAndFshipText.map((text, index) => (
              <li
                key={index}
                className="py-5 flex flex-col list-disc text-2xl font-bold"
              >
                {text.title}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  );
}
