import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";

import { Helmet } from "react-helmet";
import ScrollToTop from "react-scroll-to-top";

export default function Layout({ children, helmet={}, onlyHeader=true, headerText }) {
  return (
    <>
      <Helmet>
        { helmet?.title && <title>{helmet?.title} | Soo Yon Ryu</title> }
      </Helmet>
      <div className="flex flex-col items-center w-screen min-h-screen overflow-x-hidden">
        <ScrollToTop smooth style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: "100px", width: "50px", height: "50px" }} />
        <Navbar />
        { onlyHeader && <Header headerText={headerText} /> }
        { children }
        <Footer />
      </div>
    </>
  );
}
