import Header from "../components/Header";
import Layout from "../layouts/Layout";

export default function Teaching() {
  const teachingText = [
    {
      title:
        "Instructor: Marketing (Graduate: Masters, Spring 2025, University of Florida)",
    },
    {
      title:
        "Guest Lecturer: Undergraduate Research in Business (Undergraduate, Fall 2024, University of Florida)",
    },
    {
      title:
        "Guest Lecturer: Organizational Research Methods (Graduate: Doctor of Business Administration, Spring 2024, University of Florida)",
    },
    {
      title:
        "Guest Lecturer: Undergraduate Research in Business (Undergraduate, Fall 2023, University of Florida)",
    },
    {
      title:
        "Invited Panelist: Preparing to Teach Business (Graduate: Ph.D., Summer 2023, University of Florida)",
    },
    {
      title:
        "Instructor: Marketing Management (Undergraduate, Spring 2023, University of Florida)",
      desc: [
        {
          item: "Instructor rating: 4.96 / 5.00 (college average: 4.25 / 5.00)",
        },
        { item: "Warrington College of Business Ph.D. Teaching Award" },
      ],
    },
    {
      title:
        "Teaching Assistant: Marketing Management (Undergraduate, Fall 2019, Spring 2020), Arts and Management (Graduate; Fall 2020, Seoul National University)",
    },
    {
      title:
        "Teaching Assistant: Management Strategy for the Arts (Graduate, Fall 2018, Seoul National University)",
    },
  ];

  return (
    <Layout onlyHeader={false} helmet={{ title: "Teaching" }}>
      <Header headerText="Teaching" />
      <div className="pt-12">
        <img
          src="/images/ryusu_teaching.webp"
          alt="Sooyon Ryu, Teaching"
          width="600"
        />
      </div>
      <div className="w-full bg-white flex flex-col items-center font-main">
        <div className="w-4/5 md:w-2/3 lg:w-1/2 my-10">
          {teachingText.map((text, index) => (
            <div className="py-10 flex flex-col" key={index}>
              <div className="text-2xl font-bold">{text.title}</div>
              {/* <div className="text-xl mt-2">{text.subtitle}</div> */}
              {text.desc && (
                <div className="mt-4">
                  {text.desc.map((d, index2) => (
                    <ul key={index2}>
                      <li className="list-disc ml-6 text-lg">{d.item}</li>
                      {d.subItem &&
                        d.subItem.map((i, index3) => (
                          <li key={index3} className="list-disc ml-14 text-lg">
                            {i}
                          </li>
                        ))}
                    </ul>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}
